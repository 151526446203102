.section1innerside2 {
    display: flex;
    align-items: center;
    padding: 10px;
}

.section1innerside2-ptags {
    display: flex;
    align-items: center;
}

@media (max-width: 575.98px) {

    .appinfoSection-1,
    .appinfoSection-2,
    .appinfoSection-3,
    .appinfoSection-4 {
        font-size: 2.8vw;
    }

    .h1tagStyle {
        height: 40px;
    }

    .iconImg {
        height: 10px;
    }
}

p {
    margin-bottom: 0px;
}

.section-1-RatingSection {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.h1tagStyle {
    background-color: rgba(0, 0, 0, .2);
    height: 20px;
    width: 2px;
    display: flex;
    margin: 0%;
    padding: 0%;
}