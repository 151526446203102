.userRatingNumber-5 {
    margin-top: 5px;
    margin-right: 5px;
    font-size: .75rem;
}

.gamePlay-Section3 {
    display: flex;
    align-items: center;
}

.userRating-5 {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, rgba(57, 135, 95, 1) 75%, rgba(57, 135, 95, 1) 75%, rgba(245,245,245,1) 75%);
    border-radius: 8px;
}

.userRating-4 {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, rgba(57,135,95,1) 60%, rgba(57,135,95,1) 60%, rgba(245,245,245,1) 60%);
    border-radius: 8px;
}

.userRating-3 {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, rgba(57,135,95,1) 50%, rgba(57,135,95,1) 50%, rgba(245,245,245,1) 50%);
    border-radius: 8px;
}

.userRating-2 {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, rgba(57,135,95,1) 20%, rgba(57,135,95,1) 20%, rgba(245,245,245,1) 20%);
    border-radius: 8px;
}

.userRating-1 {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, rgba(57,135,95,1) 0%, rgba(57,135,95,1) 0%, rgba(245,245,245,1) 0%);
    border-radius: 8px;
}

.section1innerside2-ptags {
    display: flex;
    align-items: center;
    overflow-x: scroll;
}