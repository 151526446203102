.section-4 {
    box-sizing: border-box;
}

.multirow {
    width: auto;
    margin-right: 0%;
    margin-left: 0%;
}

.avtarDiv {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: large;
    font-weight: 400;
}

.ownerNameAndDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ownerMessage-Innerside {
    background-color: #eee;
}