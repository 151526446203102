@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@400&display=swap');

*,
body {
    box-sizing: border-box;
    font-family: 'Yantramanav', sans-serif;
}

.mainSection {
    height: 100vh;
    display: flex;
}

.cardSection {
    align-items: center;
    justify-content: center;
    display: flex;
}