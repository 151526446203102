.imgDiv {
    margin-left: 3px;
    height: 26rem;
    width: fit-content;
    background-color: transparent;
}

.imgDiv:hover {
    box-shadow: 0px 1px 3px 0px gray;
}

.carDiv {
    overflow-x: scroll;
    overflow-y: hidden;
}

.infoimgstyle {
    height: inherit;
    width: 230px;
}